<template>
  <div>
        <pageHeader :pagename="'用户组管理'" />

        <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="搜索">
                <el-input placeholder="用户名，手机号，邮箱" v-model="fromData.keyword"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="getTabel()">查询</el-button>
            </el-form-item>

            <el-form-item style="float:right">
                <el-button type="primary" @click="addFormButton()">创建用户组</el-button>
            </el-form-item>
        </el-form>

        <el-divider></el-divider>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%">

                    <el-table-column prop="id" label="系统ID"  width="100" align="center"> </el-table-column>
                    <el-table-column prop="group_name" label="用户组名"> </el-table-column>
                    <el-table-column prop="group_info" label="用户组说明"> </el-table-column>

                    <el-table-column fixed="right" width="300" label="操作">
                        <template slot-scope="scope">
                            <!-- <el-button @click="goToedit(scope.row.id,scope.row.roles_name,scope.row.roles_info)" type="primary"
                            >编辑权限（接口暂时未定义）</el-button
                            > -->
                            <el-button @click="editUser(scope.row)">编辑用户组信息</el-button>
                            <el-button @click="delUser(scope.row)" type="danger">删除该用户组</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>

        <!-- 点击事件触发 -->
        <el-dialog title="编辑用户组信息" :visible.sync="dialogFormVisibleEdit">
        <el-form :model="formEdit">
            <el-form-item label="用户组名" label-width="120px">
            <el-input v-model="formEdit.group_name"></el-input>
            </el-form-item>
            <el-form-item label="用户组信息" label-width="120px">
            <el-input v-model="formEdit.group_info" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisibleEdit = false">取 消</el-button>
            <el-button type="primary" @click="updateFirstLevel()">确 定</el-button>
        </div>
        </el-dialog>

        <el-dialog title="新增用户组" :visible.sync="dialogFormVisibleAdd">
        <el-form :model="formAdd">
            <el-form-item label="用户组名" label-width="120px">
            <el-input v-model="formAdd.group_name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="用户组信息" label-width="120px">
            <el-input v-model="formAdd.group_info" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
            <el-button type="primary" @click="createFirstLevel()">确 定</el-button>
        </div>
        </el-dialog>
    </div>
</template>

<script>
import { personnelAccountGroupQuery,personnelAccountGroupDeleteGroup,personnelAccountGroupDeleteUpdate,personnelAccountGroupDeleteCreate} from "@/api/manage/personnel_account_group.js"
// import {garage_account_get } from "@/api/manage/business/garage/garage.js";
export default {
  name: "start",
  data() {
    return {
        fromData:{
            keyword:null
        },
      //分页数据
      tableData: [],
      dialogFormVisibleEdit: false,
      formEdit: { group_info: "" },
      dialogFormVisibleAdd: false,
      formAdd: {}
    };
  },
  components: { },
  created() {
      this.getTabel()
  },
  methods: {
    getTabel() {
        this.$http(personnelAccountGroupQuery(this.fromData),
            (res) => {
                this.tableData =  res.data;
            }
        );
    },
    editUser(row) {
      this.formEdit = {
        group_id: row.id,
        group_name: row.group_name,
        group_info: row.group_info,
      };
      console.log(this.form,"ddd")
      this.dialogFormVisibleEdit = true;
    },
    delUser(row) {
      let ids = row.id;
      console.log({ group_id: ids });
      this.$confirm("是否确定删除", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "删除",
        cancelButtonText: "放弃删除",
      })
        .then(() => {
          personnelAccountGroupDeleteGroup({ group_id: ids }).then((res) => {
            if (res.code == 200) {
              this.getTabel()
            }
          });
        })
        .catch((action) => {
          this.$message({
            type: "info",
            message: action === "cancel" ? "放弃删除" : "停留在当前",
          });
        });
    },
    createFirstLevel() {
      personnelAccountGroupDeleteCreate(this.formAdd).then((res) => {
        if (res.code == 200) {
          this.$message.success("成功添加");
          // window.location.reload();
          this.$router.push(1)
        } else {
          this.$message.info(res.msg);
        }
      });
      this.dialogFormVisibleEdit = false;
    },
    addFormButton() {
      this.dialogFormVisibleAdd = true;
    },
    updateFirstLevel() {
      console.log(this.formEdit)
      personnelAccountGroupDeleteUpdate(this.formEdit).then((res) => {
        if (res.code == 200) {
          this.$message.success("成功修改");
          // window.location.reload();
          this.$router.push(1)

        } else {
          this.$message.info(res.msg);
        }
      });
      this.dialogFormVisibleEdit = false;
    }
  },
};
</script>

<style type="scss">
.a {
  margin-left: 20%;
}
</style>
